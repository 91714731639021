export default {
  // 登录页
  loginPage: {
    area: '국가 번호',
    areaMsg: '국가 번호를 선택하세요',
    phone: '휴대폰',
    phoneMsg: '휴대폰 번호를 입력하세요',
    name: '닉칭',
    nameMsg: '닉칭을 입력하세요',
    password: '비밀번호',
    passwordMsg: '비밀번호를 입력하세요',
    confirmPassword: '비밀번호 확인',
    confirmpsd: '비밀번호 확인',
    code: '추천 코드',
    codeMsg: '추천 코드를 입력하세요',
    termes: '회원 가입 시 利用 약관에 동의함을 뜻십니다',
    rappelez: 'ID 저장',
    register: '회원 가입',
    login: '로그인',
    account: '이미 계정이 존재하나요?',
    gologing: '로그인으로 이동',
    noAccount: '계정이 없나요?',
    goregister: '회원 가입으로 이동',
    pasdMage: '비밀번호는 6-20개의 움페와 숫자로 이루어져야 합니다',
    loginSuccess: '로그인 성공',
    registerSuccess: '회원 가입 성공, 로그인해주세요',
    clause: '이용 약관에 동의하셔야 합니다!',
    noPasd: '비밀번호가 일치하지 않습니다!'
  },
  // 区号选择
  areaPage: {
    searchMsg: '국가 번호를 입력하세요',
    sub: '확인'
  },
  // tabbar页
  tabbarPage: {
    home: '홈',
    order: '주문',
    trade: '거래',
    team: '팀',
    user: '사용자',
  },
  // 首页
  homePage: {
    income: '累計 수익',
    myService: '내 서비스',
    about: '会社紹介',
    partner: 'パートナー',
    title: '거래 측정 보조 도구',
    newStart: '지금 시작',
    dayHot: '本日人気商品',
    price: '가격',
    daySales: '일 살팔',
    rate: '일 증가율',
    moduleTit1: 'TikTok 전용 거래 측정 보조 도구',
    moduleText1: 'TikTok Shop 거래 측정 보조 도구는 자동 거래를 전문으로 담당하는 AI 거래 플랫폼으로서, 첨단 알고리즘과 데이터 분석 도구를 융합, 거래 시장에서 수익을 늘리도록 도와드릴 수 있습니다.',
    moduleTit2: '인지능 AI 거래 기술',
    moduleText2: '우리의 첨단 AI 기술을 이용, 시장 데이터를 정밀 분석 및 거래 수행을 자동화, 효율을 높이도록 돕니다.',
    moduleTit3: '다양한 거래 전략',
    moduleText3: '다양한 거래 전략을 제공하여 다양한 ニーズ를 충족시키며, 시장 움동에 맞춰 융통성 있게 전략을 조정합니다.',
    moduleTit4: '안정적인 リスク 관리',
    moduleText4: '엄격한 リスク 컨트롤 시스템을 갖춘하여 거래를 안정적, 안전하게 이룰 수 있도록 보장, 이용자 자금에 불필요한 リスク이를 노출시키지 않습니다.',
    moduleTit5: '専門 利用者 지원',
    moduleText5: '専門 팀은 언제든지 専門 지원을 제공, 문제가 발생 시, 고객 サポート 팀은 시속 시에 도움을 줄 수 있습니다.',
  },
  // 订单页
  orderPage: {
    order: '주문',
    notFinished: '미완료',
    completed: '완료',
    more: '더 이상 없음',
    orderNum: '주문 번호',
    tradeTime: '거래 시간',
    payTime: '결제 시간',
    amount: '금액',
    newTaskNum: '현재 タスク 수',
    expectedReturns: '予想 수익',
    noData: '데이터 없음',
    placeOrder: '주문',
    cancel: '취소',
    close: '닫기',
    noBalance: '잔액 부족',
    needRecharge: '충전이 필요',
    gorecharge: '충전으로 이동',
    over: '완료'
  },
  // 交易页
  tradePage: {
    title: '거래',
    account: '계좌 잔액',
    introduceTitle: '거래 소개',
    introduceText: 'TiktokShop는 매일 TiktokShop 피드백을 이용하여 TiktokShop 판매에서 개선이 필요한 商品을 공개합니다. 利用者は 주문을 클릭만으로 시스템은 자동으로 주문 서명을 생성합니다. 利用者は USDT 블록체인을 통해 주문 금액을 결제, 매번 0.6% 이상의 수수료를 획득, 시스템은 랜덤으로 대규모 보상 주문을 배정합니다.',
    dayProfit: '本日 수익',
    tradeTime: '거래 시간',
    price: '금액',
    profit: '予想 수익',
    startTrade: '거래 시작',
    close: '취소',
    tIncome: '累計 タスク 수익',
    cIncome: '累計 수수료 수익',
    match: '한 번에 매칭 시작',
    Completed: 'タスク 완료',
    totalTask: 'タスク 총 수',
    income: '현재 라운드 수익',
    lrl: '이율율',
    ydlr: '予想 이윤',
    ppz: '매칭 중',
    trade: '거래 중'
  },
  // 团队页
  teamPage: {
    title: '팀',
    money: '금액',
    commission: '수수료',
    receive: '領収',
    myTeam: '내 팀',
    total: '총 인원',
    user: '利用者',
    contribution: '貢献(タスク)',
    status: '상태'
  },
  // 我的页面
  userPage: {
    noLogin: '미 로그인',
    id: 'ID',
    account: '내 계정',
    freezeAmount: '얼라져 된 금액',
    needUop: '충전이 필요한 금액',
    withdrawal: '출금',
    recharge: '충전',
    invitation: '친구를 새롭이 초대',
    invitationText: '친구가 등록 시에 얻은 수익의 일정 비율의 수수료를 획득이 가능',
    nowInvitation: '지금 초대',
    userInfo: '개인 정보',
    financial: '자금 명세',
    rechargeRecord: '충전 기록',
    withdrawalRecord: '현금 철회 기록',
    lang: '언어',
    out: '로그아웃',
    copy: '복사 성공',
    load: '로딩 중...',
    outMsg: '로그아웃을 진행하시겠습니까?',
    msg: '거래 보조 프로그램, 응대중',
    team: '팀',
    help: '도움말',
    customer: '고객 서비스',
  },
  // 현금 철회 페이지
  withdrawalPage: {
    title: '현금 철회',
    mode: '지불 방법',
    address: '현금 철회 주소',
    balance: '이용 가능 잔액',
    money: '철회 금액',
    account: '실제로 수령',
    serviceCharge: '현금 철회 수수료는',
    submit: '제출',
    ipt: '철회 금액은 이용 가능 잔액을 초과할 수 없습니다',
    isSub: '심사 중',
    isAddress: '먼저 현금 철회 주소를 등록하시기 바랍니다',
    noAccount: '철회 가능 잔액 부족'
  },
  // 충전 페이지
  rechargePage: {
    title: '충전',
    submit: '제출',
    reMode: '충전 방법',
    reMoney: '충전 금액',
    certificate: '자격 증명 업로드',
    moneyMsg: '금액을 입력해주세요',
    qrCode: 'QR 코드 충전',
    copyClick: '클릭 복사',
    copy: '복사 성공',
    success: '충전 성공',
    submitMsg: '제출 성공, 심사 중',
    jg: '다른 지불 채널이 필요한 경우 고객 서비스에 문의하십시오',
    lxkf: '고객 서비스 연락',
    text1: '지불을 오래 받지 못한 경우 페이지를 새로 고쳐주세요.',
    text2: '충전에 관련된 문제가 있을 경우 온라인 고객 서비스에 연락주십시오.',
    load: '업로드 중...',
    loadSuccess: '업로드 성공',
    loadError: '업로드에 실패했습니다. 다시 시도해 주세요.',
    isPz: '인증서를 업로드해주세요',
    rechargeMsg: '다음 방법을 선택하여 동액 전액을 송금하여 철저한 철사 검사를 피하십시오',
    detail: '충전 상세 사항',
    orderId: '주문 번호',
    time: '시간',
    plase: '계정을 사용해주세요',
    tran: '입금',
    toAcc: '계좌로',
    newAccount: '현재 수령된 금액',
    results: '결과를 얻음'
  },
  // 친구 참여 페이지
  InvitePage: {
    title: '친구 참여',
    InviteCode: '참여 코드',
    InviteText: '친구를 참여시켜 큰 이익을 즐기세요, 빨리 참여하세요',
    copyClick: '클릭 복사',
    copy: '복사 성공',
    text1: 'TikTok Shop 거래 보조 프로그램을 전 세계에 전달하여 누구나 즐거움과 보람을 느낄 수 있게 해주세요.',
    text2: '친구를 참여시키면 친구의 수익의 15% 보너스를 얻을 수 있습니다!',
    text3: '참여 링크',
    btn: '참여 링크 복사'
  },
  // 개인정보 페이지
  userInfoPage: {
    title: '개인정보',
    phone: '휴대폰 번호',
    pasd: '로그인 암호',
    edit: '수정',
    yPasd: '원본 암호를 입력해주세요',
    yPasdMsg: '원본 암호를 빠질 수 없습니다!',
    newPasd: '신규 암호를 입력해주세요',
    newPasdMsg: '신규 암호를 빠질 수 없습니다',
    nowPasd: '암호 확인',
    nowPasdMsg: '암호 확인을 빠질 수 없습니다',
    close: '취소',
    submit: '확인',
    address: '수정 주소를 입력해주세요',
    sucess: '현금 철회 주소 수정 성공',
    goLogin: '암호 수정 성공, 다시 로그인해주세요',
  },
  // 자금 명세 페이지
  financialPage: {
    title: '자금 명세',
    noMore: '더 이상 없음',
    amount: '금액',
    recharge: '충전',
    orderSy: '주문 수익',
    distributionSy: '유통 수익',
    other: '기타',
    payment: '주문 지불',
    withdrawal: '현금 철회',
    frozen: '충전 잠금',
    time: '시간',
    noData: '데이터 없음',
    refresh: '새로고침 성공',
    Withdrawn: '철회 완료',
    shsb: '심사 실패',
    shz: '심사 중'
  },
  // 충전 기록 페이지
  rechargeRecordPage: {
    tab1: '전체',
    tab2: '충전',
    tab3: '현금 철회',
    tab4: '수익',
    title: '충전 기록',
    noMore: '더 이상 없음',
    amount: '금액',
    pendingPayment: '심사 중',
    paymentSec: '심사 성공',
    paymentFail: '심사 실패',
    refund: '환불',
    time: '시간',
    noData: '데이터 없음',
    refresh: '새로고침 성공',
    textMsg: '결제액의 소수점을 정확히 맞춰 송금해주세요. 그렇지 않으면, 입금이 안 됩니다.'
  },
  // 현금 철회 기록
  withdrawalRecordPage: {
    title: '현금 철회 기록',
    noMore: '더 이상 없음',
    amount: '금액',
    withdrawal: '현금 철회',
    time: '시간',
    noData: '데이터 없음',
    refresh: '새로고침 성공'
  }
}