export default {
  // 登录页
  loginPage: {
    area: 'Indicatif régional',
    areaMsg: 'Veuillez sélectionner un indicatif régional',
    phone: 'Téléphone',
    phoneMsg: 'Veuillez saisir votre numéro de téléphone',
    name: 'Pseudonyme',
    nameMsg: 'Veuillez saisir votre pseudonyme',
    password: 'Mot de passe',
    passwordMsg: 'Veuillez saisir votre mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    confirmpsd: 'Confirmer le mot de passe', // Note: This is a duplicate of 'confirmPassword', consider removing or renaming
    code: 'Code de parrainage',
    codeMsg: 'Veuillez saisir votre code de parrainage',
    termes: 'L\'inscription signifie que vous acceptez nos termes et conditions',
    rappelez: 'Se souvenir de moi',
    register: 'S\'inscrire',
    login: 'Se connecter',
    account: 'Déjà un compte ?',
    gologing: 'Aller à la connexion',
    noAccount: 'Pas de compte ?',
    goregister: 'Aller à l\'inscription',
    pasdMage: 'Le mot de passe doit contenir 6 à 20 lettres et chiffres',
    loginSuccess: 'Connexion réussie',
    registerSuccess: 'Inscription réussie, veuillez se connecter',
    clause: 'Veuillez cocher pour accepter les termes!',
    noPasd: 'Les mots de passe ne correspondent pas!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Veuillez saisir un indicatif international',
    sub: 'Confirmer'
  },
  // tabbar页
  tabbarPage: {
    home: 'Accueil',
    order: 'Commandes',
    trade: 'Transactions',
    team: 'Équipe',
    user: 'Utilisateur'
  },
  // 首页
  homePage: {
    income: 'Bénéfices cumulés',
    myService: 'Mes services',
    about: 'À propos de nous',
    partner: 'Partenaires',
    title: 'Assistant de transactions',
    newStart: 'Commencer immédiatement',
    dayHot: 'Produits les plus vendus aujourd\'hui',
    price: 'Prix',
    daySales: 'Ventes journalières',
    rate: 'Taux de croissance journalier',
    moduleTit1: 'Assistant de transactions dédié à TikTok',
    moduleText1: "TikTok Shop Assistant est une plateforme d'échange artificielle intelligente axée sur les transactions automatiques. Notre plateforme intègre des algorithmes et des outils d'analyse de données de pointe pour aider les utilisateurs à augmenter leurs bénéfices sur les marchés des transactions.",
    moduleTit2: 'Technologie de transactions artificielle intelligente',
    moduleText2: "Grâce à notre technologie avancée d'intelligence artificielle, nous analysons précisément les données du marché et exécutons les transactions pour réaliser une opération automatisée et améliorer l'efficacité des transactions.",
    moduleTit3: 'Stratégies de transaction diversifiées',
    moduleText3: "Nous offrons plusieurs stratégies de transaction pour répondre aux besoins des différents utilisateurs et nous ajustons flexiblement aux changements du marché.",
    moduleTit4: 'Gestion des risques solide',
    moduleText4: "Nous sommes équipés d'un système de contrôle des risques strict pour garantir la sécurité et la stabilité des transactions et éviter que les fonds des utilisateurs soient exposés à des risques inutiles.",
    moduleTit5: 'Support professionnel pour les utilisateurs',
    moduleText5: "Notre équipe professionnelle est prête à fournir un support professionnel aux utilisateurs. Si vous rencontrez un problème, notre équipe de service clientèle vous aidera rapidement."
  },
  // 订单页
  orderPage: {
    order: 'Commande',
    notFinished: 'Non terminée',
    completed: 'Terminée',
    more: 'Plus d\'éléments',
    orderNum: 'Numéro de commande',
    tradeTime: 'Heure de la transaction',
    payTime: 'Heure du paiement',
    amount: 'Montant',
    newTaskNum: 'Nombre de tâches actuelles',
    expectedReturns: 'Bénéfices attendus',
    noData: 'Aucune donnée pour l\'instant',
    placeOrder: 'Passer la commande',
    cancel: 'Annuler',
    close: 'Fermer',
    noBalance: 'Solde insuffisant',
    needRecharge: 'Recharge nécessaire',
    gorecharge: 'Voulez-vous effectuer une recharge ?',
    over: 'Terminé'
  },
  // 交易页
  tradePage: {
    title: 'Transactions',
    account: 'Solde du compte',
    introduceTitle: 'Introduction aux transactions',
    introduceText: "TiktokShop utilise chaque jour les commentaires de TiktokShop pour présenter les produits TiktokShop à améliorer. Les utilisateurs n\'ont qu\'à cliquer sur une commande et le système génère automatiquement une signature de commande. Les utilisateurs payent le montant de la commande via la blockchain USDT et obtiennent chaque fois une commission supérieure à 0.6%, et le système attribue aléatoirement des commandes récompensées à montant élevé.",
    dayProfit: 'Bénéfices du jour',
    tradeTime: 'Heure de la transaction',
    price: 'Montant',
    profit: 'Bénéfices attendus',
    startTrade: 'Commencer la transaction',
    close: 'Annuler',
    tIncome: 'Bénéfices cumulés des tâches',
    cIncome: 'Bénéfices cumulés des commissions',
    match: 'Mise unique',
    Completed: 'Tâche terminée',
    totalTask: 'Total des tâches',
    income: 'Revenus de ce tour',
    lrl: 'Taux de marge',
    ydlr: 'Bénéfices attendus',
    ppz: 'En cours de mise',
    trade: 'En cours de transaction'
  },
  // 团队页
  teamPage: {
    title: 'Équipe',
    money: 'Montant',
    commission: 'Commission',
    receive: 'Recevoir',
    myTeam: 'Mon équipe',
    total: 'Total des membres',
    user: 'Utilisateur',
    contribution: 'Contribution (tâches)',
    status: 'État'
  },
  // 我的页面
  userPage: {
    noLogin: 'Non connecté',
    id: 'Numéro',
    account: 'Mon compte',
    freezeAmount: 'Montant gelé',
    needUop: 'Montant à recharger',
    withdrawal: 'Retrait',
    recharge: 'Recharge',
    invitation: 'Invitez vos amis',
    invitationText: 'Pour chaque bénéfice gagné par un ami enregistré, vous recevrez une commission correspondante',
    nowInvitation: 'Inviter maintenant',
    userInfo: 'Informations personnelles',
    financial: 'Détails financiers',
    rechargeRecord: 'Historique de recharge',
    withdrawalRecord: 'Historique de retrait',
    lang: 'Langue',
    out: 'Déconnexion',
    copy: 'Copié avec succès',
    load: 'En cours de chargement...',
    outMsg: 'Êtes-vous sûr de vouloir vous déconnecter?',
    msg: 'Assistant de transactions, à votre service',
    team: 'Équipe',
    help: 'Aide',
    customer: 'Service client',
  },
  // 提现页面
  withdrawalPage: {
    title: 'Retrait',
    mode: 'Mode de paiement',
    address: 'Adresse de retrait',
    balance: 'Solde disponible',
    money: 'Montant du retrait',
    account: 'Montant reçu',
    serviceCharge: 'Frais de retrait de',
    submit: 'Soumettre',
    ipt: 'Le montant du retrait ne peut être supérieur au solde disponible',
    isSub: 'En attente d\'approbation',
    isAddress: 'Veuillez d\'abord lier une adresse de retrait',
    noAccount: 'Montant de retrait insuffisant'
  },
  // 充值页面
  rechargePage: {
    title: 'Recharge',
    submit: 'Soumettre',
    reMode: 'Mode de recharge',
    reMoney: 'Montant de recharge',
    certificate: 'Télécharger la preuve',
    moneyMsg: 'Veuillez saisir le montant',
    qrCode: 'Recharge par code QR',
    copyClick: 'Cliquer pour copier',
    copy: 'Copié avec succès',
    success: 'Recharge réussie',
    submitMsg: 'Soumission réussie, en cours d\'approbation',
    jg: 'Pour d\'autres canaux de paiement, veuillez contacter le service client',
    lxkf: 'Contacter le service client',
    text1: 'Si vous ne recevez pas le paiement pendant longtemps, veuillez rafraîchir la page.',
    text2: 'Pour toute question de recharge, veuillez contacter notre service client en ligne.',
    load: 'Téléchargement en cours...',
    loadSucess: 'Téléchargement réussi',
    loadError: 'Le téléchargement a échoué, veuillez réessayer',
    isPz: 'Veuillez télécharger le certificat',
    rechargeMsg: "Veuillez choisir le mode de transfert suivant pour un montant équivalent, afin d'éviter des retards dans les contrôles financiers",
    detail: "Détails du rechargement",
    orderId: "Numéro de commande",
    time: "Heure",
    plase: 'Veuillez utiliser votre compte',
    tran: 'Transfert vers',
    toAcc: 'vers le compte',
    newAccount: 'Montant reçu actuellement',
    results: 'Obtenir les résultats'
  },
  //邀请好友页
  InvitePage: {
    title: 'Invitez vos amis',
    InviteCode: 'Code d\'invitation',
    InviteText: 'Invitez vos amis et bénéficiez de gains importants. Invitez-les dès maintenant!',
    copyClick: 'Cliquer pour copier',
    copy: 'Copié avec succès',
    text1: 'Ensemble, transmettons TikTok Shop Assistant à le monde entier pour que chacun puisse éprouver le plaisir et la récompense.',
    text2: 'Invitez des amis et recevez une récompense de 15% des revenus de vos amis!',
    text3: 'Lien d\'invitation',
    btn: 'Copier le lien d\'invitation'
  },
  // 个人信息页
  userInfoPage: {
    title: 'Informations personnelles',
    phone: 'Numéro de téléphone',
    pasd: 'Mot de passe de connexion',
    edit: 'Modifier',
    yPasd: 'Veuillez saisir votre ancien mot de passe',
    yPasdMsg: 'L\'ancien mot de passe ne peut être vide!',
    newPasd: 'Veuillez saisir votre nouveau mot de passe',
    newPasdMsg: 'Le nouveau mot de passe ne peut être vide',
    nowPasd: 'Confirmer le mot de passe',
    nowPasdMsg: 'La confirmation du mot de passe ne peut être vide',
    close: 'Annuler',
    submit: 'Confirmer',
    address: 'Veuillez saisir l\'adresse à modifier',
    sucess: 'Adresse de retrait modifiée avec succès',
    goLogin: 'Mot de passe modifié avec succès, veuillez se reconnecter',
  },
  // 资金明细页
  financialPage: {
    title: 'Détails financiers',
    noMore: 'Plus d\'éléments',
    amount: 'Montant',
    recharge: 'Recharge',
    orderSy: 'Bénéfices des commandes',
    distributionSy: 'Bénéfices de distribution',
    other: 'Autre',
    payment: 'Paiement de commande',
    withdrawal: 'Retrait',
    frozen: 'Gelé',
    time: 'Heure',
    noData: 'Pas de données',
    refresh: 'Rafraîchi avec succès',
    Withdrawn: 'Retrait effectué',
    shsb: 'Échec d\'approbation',
    shz: 'En cours d\'approbation'
  },
  // 充值记录页面
  rechargeRecordPage: {
    tab1: 'Tout',
    tab2: 'Recharge',
    tab3: 'Retrait',
    tab4: 'Bénéfices',
    title: 'Historique de recharge',
    noMore: 'Plus d\'éléments',
    amount: 'Montant',
    pendingPayment: 'En cours d\'approbation',
    paymentSec: 'Approbation réussie',
    paymentFail: 'Échec d\'approbation',
    refund: 'Remboursement',
    time: 'Heure',
    noData: 'Pas de données',
    refresh: 'Rafraîchi avec succès',
    textMsg: 'Veuillez impérativement effectuer le versement conformément à la virgule figurant à la fin du montant du paiement, sinon le montant ne sera pas crédité !'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'Historique de retrait',
    noMore: 'Plus d\'éléments',
    amount: 'Montant',
    withdrawal: 'Retrait',
    time: 'Heure',
    noData: 'Pas de données',
    refresh: 'Rafraîchi avec succès'
  }
}